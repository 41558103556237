<template>

<CRow>
      <CCol sm="12" md="12">
        <CCard>
          <CCardHeader>
            <strong>Version 1.1 released</strong>
            <div class="card-header-actions">
                <small class="text-muted">31/10/2023</small>            
            </div>
          </CCardHeader>
          <CCardBody>
          <p>
            In the last months, we put much effort into improving the HAWK Tool and added some new features.
            <br><br>
            The new features are:
            <ul>
              <li> <b>Multi-Region Support</b> - HAWK supports AWS WAfR Workloads in three regions (eu-central-1, us-east-1, ap-southeast-1).  </li>
              <li><b> Create Workloads </b> - Create AWS WAfR Workloads directly via the HAWK Tool, including fixed names, tags and other benefits.</li>
              <li><b> Workloads Shares & Milestones </b> - Share workloads and create milestones.  </li>
              <li><b> Fixed Reports </b> -  Report can't be reassigned to other Customers or Workloads afterwards and have a specified name.</li>
              <li><b> Genenerate Reports </b> - Improved performance due to variues changes (from ~60s to ~10s).</li>
              <li><b> Improvement Items </b> - Scraped items are now available via the console and can be discovered.</li>
              <li><b> Customers </b> - Now have an email address and description field in preparation for sending out automated emails.</li>
            </ul>
          </p>      
          <p>Please continue to report any feedback and bugs in our Slack Channel <a target="_blank" href="https://doitintl.slack.com/archives/C0543PNH4H3">#hawk-tool</a>!</p>
          </CCardBody>
          <CCardFooter>
            <div class="card-footer-actions">
                <small class="text-muted">Fabian Lober &lt;fabian@doit.com&gt;</small>            
            </div>                        
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol sm="12" md="12">
        <CCard>
          <CCardHeader>
            <strong>Initial version 1.0 released</strong>
            <div class="card-header-actions">
                <small class="text-muted">24/07/2023</small>            
            </div>
          </CCardHeader>
          <CCardBody>
          <p>Welcome to the inital release version of the DoiT HAWK Tool!</p>
          <p>
            This tool is part of our effort to standardize the WAfR process across DoiT and make it more efficient. 
            <br><br>            
            Our experience has shown that creating valuable reports with remediation plans and recommendations was one of the most time-consuming tasks.<br>
            The HAWK tool can create reports in minutes instead of hours, include more details and provide these as a website to our customers. 
            <br><br>
            The main features are:
            <ul>
              <li> Create a unified version for all of our AWS WAfR and provide the customer with a more streamlined & valuable report. </li>
              <li> Track the findings of a Workload over time.</li>
              <li> Collect and show all available choices of a lens with the risk impact.</li>
            </ul>
          </p>
          
          <p>Please report any Feedback & Bugs in our Slack Channel <a target="_blank" href="https://doitintl.slack.com/archives/C0543PNH4H3">#hawk-tool</a>!</p>
          </CCardBody>
          <CCardFooter>
            <div class="card-footer-actions">
                <small class="text-muted">Fabian Lober &lt;fabian@doit.com&gt;</small>            
            </div>            
            
          </CCardFooter>
        </CCard>
      </CCol>
 </CRow>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {

    }
  },
  methods: {
  }
}
</script>
